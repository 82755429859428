<footer class="mt-5 pb-3 footer">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-12 col-md-6">
        <p>© Uphold 2022. All Rights Reserved.</p>
      </div>

      <div class="col-12 col-md-6">
        <ul>
          <li>Follow Us</li>
          <li><img src="assets/img/icon-youtube.png" alt="" /></li>
          <li><img src="assets/img/icon-instagram.png" alt="" /></li>
          <li><img src="assets/img/icon-twitter.png" alt="" /></li>
        </ul>
      </div>
    </div>
  </div>
</footer>
