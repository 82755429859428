// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// https://www.rapidtables.com/convert/number/hex-to-decimal.html

export const environment = {
  production: false,
  projectName: "Growland",
  API_URL: '#',
  urlWeb: '#',
  configUrlAbi: "/assets/abi/DevToken.json",
  // Main Contract vendor: 0x9556D0Fc988Def92de4fA05287e946a77A91B911
  /// ContratosNFT
  // Hills - 145 :        0x9046B80A705d19f3cD8F27a162d5fB58740A9b09  
  // Heighst - 185 :      0x7f11e9b35ffC1aABF506Fe3358FEFD13e2A89C1d
  // Estates - 505 :      0xF630B00F5801Ef98279f294484106dCB4238B8FA 
  // Growtopia - 1205 :   0xad9aF0319CD165B04839C31884b361406a803611
  // General - 30005:     0x6a7e50c6978D5303434E87b02e726950F798d22C
  contractAddress: "0x13F0aE41452CdAB9253f95D401E01f54dB94aA7D", /// main contract
  marketplaceAddress: "",
  infuraId: "#",
  urlTokenLogo: "#",
  mainToken: {
    contract: "0xB735eb7f14E223e36aDFB6e9b8fD55797792a351",
    name: "DAPP TEST",
    symbol: "DPPT",
    decimals: 18,
  },
  nftCollectionContract: "0x3040AA2F1669a791b0ebe542de6FD3616Ac3efdF",
  chain: {

    // Testnet
    chainId: 97,
    chainIdMetamask: "0x61",
    chainName: "BNB Smart Chain Testnet",
    rpc: "https://data-seed-prebsc-1-s1.binance.org:8545/",
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com/"],

    // Mainnnet 
    // chainId: 56,
    // chainIdMetamask: "0X38",
    // chainName: "BNB Smart Chain Mainnet",
    // rpc: "https://bsc-dataseed1.binance.org/",
    // rpcUrls: ["https://bsc-dataseed1.binance.org/"],
    // blockExplorerUrls: ["https://bscscan.com/"],

    nativeCurrency: {
      name: "BNB",
      symbol: "BNB",
      decimals: 18,
    },
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
