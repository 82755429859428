<div class="bg-home">
  <div class="container mb-3 mb-lg-4">
    <app-nav-bar></app-nav-bar>
  </div>

  <div class="container container-home mb-5">
    <div class="row align-items-center">
      <div class="col-12 col-lg-5 d-flex flex-column align-items-center justify-content-center flex-column">
        <h1 class="mb-4">
          Compra tu NFT lote <br />
          en <strong>Growland.</strong>
        </h1>

        <div class="card">
          <form [formGroup]="form" class="form d-flex flex-column align-items-center" (ngSubmit)="onSubmit()"
            novalidate>

            <div class="mb-4 d-flex justify-content-center align-items-center flex-column w-100">
              <p>Tipos de NFT</p>
              <div class="dropdown w-100">
                <button class="select-form form-select dropdown-toggle py-3" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <ng-container *ngIf="!vendorList; else showOptNft">
                   Elegir
                  </ng-container>

                  <ng-template #showOptNft>
                    <img src="assets/img/nft/{{ vendorList.tokenAddress }}.png" style="width: 21px" alt="" />

                    <!-- <ng-container *ngIf="!tokenToPay.isNative">
                      {{ vendorList.tokenAddress | erc20: "name" | async }}
                    </ng-container> -->

                    <!-- <ng-container *ngIf="tokenToPay.isNative">
                      {{ nativeCurrency.symbol }}
                    </ng-container> -->

                    {{ vendorList.tokenAddress | erc20: "name" | async }}
                  </ng-template>
                </button>

                <!-- <button class="select-form form-select dropdown-toggle" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="assets/img/icon-binance.png" alt="" /> BUSD
                  </button> -->
                <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1" *ngIf="vendorListNFT">
                  <li class="text-center" *ngFor="let item of vendorListNFT" (click)="selectTokenNFT(item)">
                    <img [src]="item.image" style="width: 21px" alt="" />

                    <ng-container *ngIf="!item.isNative; else showNative">
                      {{ item.tokenAddress | erc721: "name" | async }}
                    </ng-container>

                    <ng-template #showNative>
                      {{ nativeCurrency.symbol }}
                    </ng-template>
                  </li>
                </ul>
              </div>
            </div>

            <p class="text-center">¿Cuantos lotes deseas comprar?</p>
            <div class="number-input d-flex justify-content-center align-items-center mb-4">
              <div class="d-flex text-center input-value">
                <button class="btn-camp-input" type="button" (click)="handlerQuantity('sub')"
                  [disabled]="f.quantity.value == 1">
                  -
                </button>
                <input type="text" class="form-control fs-4 mx-2 wp" placeholder="1" aria-label="1"
                  aria-describedby="basic-addon2" formControlName="quantity" />
                <button class="btn-camp-input" type="button" (click)="handlerQuantity('add')"
                  [disabled]="f.quantity.value === maxBuyLimit">
                  +
                </button>
              </div>
            </div>
            <div class="mb-4 d-flex justify-content-center align-items-center flex-column w-100">
              <p>Token de pago</p>
              <p class="token-pay">
                <ng-container *ngIf="vendorList; else noCollecctionPrice">
                  {{ vendorList.priceParsed * f.quantity.value | number }} $
                </ng-container>

                <ng-template #noCollecctionPrice> ---- $ </ng-template>
              </p>

              <ng-container *ngIf="dataStatus; else noTokens">
                <div class="dropdown w-100">
                  <button class="select-form form-select dropdown-toggle" type="button" id="dropdownMenuButton1"
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <ng-container *ngIf="!tokenToPay; else showOpt">
                      <img src="assets/img/icon-binance.png" alt="" /> Elegir
                    </ng-container>

                    <ng-template #showOpt>

                      <ng-container *ngIf="!tokenToPay.isNative">
                        {{ tokenToPay.tokenAddress | erc20: "symbol" | async }}
                      </ng-container>

                      <ng-container *ngIf="tokenToPay.isNative">
                        {{ nativeCurrency.symbol }}
                      </ng-container>
                    </ng-template>
                  </button>

                  <!-- <button class="select-form form-select dropdown-toggle" type="button" id="dropdownMenuButton1"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      <img src="assets/img/icon-binance.png" alt="" /> BUSD
                    </button> -->
                  <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1" *ngIf="whiteList">
                    <li class="text-center" *ngFor="let item of whiteList" (click)="selectToken(item)">
                      <img [src]="item.image" style="width: 21px" alt="" />

                      <ng-container *ngIf="!item.isNative; else showNative">
                        {{ item.tokenAddress | erc20: "symbol" | async }}
                      </ng-container>

                      <ng-template #showNative>
                        {{ nativeCurrency.symbol }}
                      </ng-template>
                    </li>
                  </ul>
                </div>
              </ng-container>

              <ng-template #noTokens>
                <div class="dropdown w-100 d-flex justify-content-center align-items-center w-70">
                  <button class="select-form form-select dropdown-toggle disabled" type="button"
                    id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="assets/img/icon-binance.png" alt="" /> ...
                  </button>
                </div>
              </ng-template>
            </div>



            <div class="mb-4 mt-4 w-100 d-flex justify-content-center align-items-center">
              <ng-container *ngIf="dataStatus; else connectButton">

                <ng-container *ngIf="submit">
                  <button type="button" class="button" disabled>
                    <div class="d-flex justify-content-center">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  </button>
                </ng-container>
                <ng-container *ngIf="!submit">
                  <button type="submit" class="button">Comprar</button>
                </ng-container>

              </ng-container>

              <ng-template #connectButton>
                <button type="button" class="button" (click)="connect()">
                  Connect Account
                </button>
              </ng-template>
            </div>
          </form>
        </div>


      </div>
      <div class="col-12 col-lg-7 d-flex flex-column align-items-center justify-content-center bg-green">
        <ng-container *ngIf="vendorList; else noSelect">
          <img style="width: 50%;" src="assets/img/nft/{{ vendorList.tokenAddress }}.png"
            class="img-fluid animate__animated animate__fadeIn animate__slow" alt="" />
        </ng-container>
        <ng-template #noSelect>
          <div>
            <img src="assets/img/logo-grande.png" class="img-fluid animate__animated animate__fadeIn animate__slow"
              alt="" />
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>
