import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subscription } from 'rxjs';
import { ContractService } from 'src/app/services/contract.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { environment } from 'src/environments/environment';

import BigNumber from 'bignumber.js';
import { calculatePercentage, fromWei, toTokenDecimals, toWei } from 'src/app/helpers/utils';
import { Sweetalert2stepsService } from 'src/app/services/sweetalert2steps.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit, OnDestroy {

  public submit = false;
  public form!: FormGroup;
  public vm = {
    quantity: [
      { type: 'required', message: 'El campo es requerido' },
      { type: 'min', message: 'El valor minimo es 1' },
      { type: 'max', message: 'El valor maximo es 5000' },
      { type: 'pattern', message: 'El valor debe ser un numero' },
    ]
  };
  public maxBuyLimit: number = 5000;
  public dataStatus: any;
  public whiteList: any[] = [];
  public vendorList: any;
  public tokenToPay: any;
  public nativeCurrency = environment.chain.nativeCurrency;

  public sub$!: Subscription;
  vendorListNFT: any;

  constructor(
    private fb: FormBuilder,
    public contractSrv: ContractService,
    private sweetAlert2Srv: Sweetalert2Service,
    private spinner: NgxSpinnerService,
    private alertStepSrv: Sweetalert2stepsService,
  ) {
    this.buidlForm();
  }

  ngOnInit() {
    this.sub$ = this.contractSrv.dataStatus$.subscribe((data) => {
      this.dataStatus = data;

      if (!data) { return; }

      this.loadWhitelist();
    });
  }

  /**
   * Construir formulario
   */
  buidlForm() {
    this.form = this.fb.group({
      quantity: [
        1,
        [
          Validators.required,
          Validators.min(1),
          Validators.max(this.maxBuyLimit),
          Validators.pattern(/^\d+$/),
        ],
      ],
      moneda: ['BUSD', [Validators.required]],
    });
  }

  get f() { return this.form.controls; }

  async connect(){
    return await this.contractSrv.connectAccount();
  }

  /**
   * Manejar contador del formulario
   * @param type
   */
  handlerQuantity(type: string = 'add') {
    console.log(this.f);

    let snapshot = this.f.quantity.value;
    switch (type) {
      case 'add':
        snapshot = ++snapshot;
        break;
      default:
        snapshot = --snapshot;
        break;
    }

    this.form.patchValue({ quantity: snapshot < 0 ? 0 : snapshot });
  }


  /**
   * Cargar listado de tokens de white list
   * @returns 
   */
  async loadWhitelist() {
    try {
      let snapshot: any[] = await this.contractSrv.whitelist_tokenList();
      console.log('snapshot', snapshot);

      let result = snapshot.filter((item) => item.active)
        .map((item) =>
          Object.assign({ image: `assets/img/${item?.tokenAddress}.png` }, item)
        );
      this.whiteList = result;


      snapshot = await this.contractSrv.pairList();
      console.log('snapshot', snapshot);

      result = snapshot
        .map((item, index) => Object.assign({ collectionID: index }, item))
        .filter((item) => item.active);

      this.vendorListNFT = result;

      console.log('this.vendorListNFT', this.vendorListNFT);

      return;

    } catch (err) {
      console.log('HomeComponent.loadWhitelist', err);
      this.whiteList = [];
      return;
    }
  }

  selectTokenNFT(item: any) {
    console.log('item', item);
    this.vendorList = null;
    setTimeout(() => {
      this.vendorList = Object.assign({}, item, {
        totalPrice: new BigNumber(item.price).plus(calculatePercentage(item.price, item.fee)).toString(),
        priceParsed: Number(fromWei(item.price, 18))
      })
      console.log('this.vendorList', this.vendorList);
    }, 500);
  }

  async selectToken(item: any) {
    this.tokenToPay = item;
    this.form.patchValue({ quantity: 1 });
  }


  async onSubmit() {
    try {

      console.log('this.form.value', this.form.value);
      this.submit = true;

      if (this.form.invalid) {
        this.form.markAllAsTouched();
        return;
      }

      if (!this.vendorList) {
        this.sweetAlert2Srv.showError('No collection selected, please select a collection first');
        return;
      }

      if (!this.tokenToPay) {
        this.sweetAlert2Srv.showError('No token selected, please select one');
        return;
      }

      await this.spinner.show();

      const { price,totalPrice, tokenAddress: nftContract, collectionID } = this.vendorList;
      console.log('vendorList', { price, totalPrice, tokenAddress: nftContract, collectionID });

      const { tokenAddress, addressOracle, addressDecimalOracle, isNative } = this.tokenToPay;
      console.log('tokenAddress', { tokenAddress, addressOracle, addressDecimalOracle, isNative });

      /** Obtener decimales del token seleccionado */
      const tokenDecimals = (!isNative)
        ? await this.contractSrv.calculateAndCallCustomABI({
          contractAddress: tokenAddress,
          method: 'decimals',
          params: null,
          callType: 'call',
          urlABI: this.contractSrv.erc20ABI
        })
        : this.nativeCurrency.decimals;


      const tokenSymbol = (!isNative)
        ? await this.contractSrv.calculateAndCallCustomABI({
          contractAddress: tokenAddress,
          method: 'symbol',
          params: null,
          callType: 'call',
          urlABI: this.contractSrv.erc20ABI
        })
        : this.nativeCurrency.symbol;

      const nftSymbol = await this.contractSrv.calculateAndCallCustomABI({
        contractAddress: nftContract,
        method: 'name',
        params: null,
        callType: 'call',
        urlABI: this.contractSrv.erc721ABIf
      })

      console.log('tokenDecimals', tokenDecimals);
      console.log('tokenSymbol', tokenSymbol);
      console.log('nftSymbol', nftSymbol);


      /** Obtener precio del oraculo */
      const oraclePrice = await this.contractSrv.getLatestPrice(addressOracle, addressDecimalOracle);
      console.log('oraclePrice', oraclePrice);

      const unity = new BigNumber(10).pow(18);
      let amounForToken = new BigNumber(totalPrice).multipliedBy(unity).dividedBy(oraclePrice);
      console.log('amountForToken', amounForToken.toString());

      /** Truncar monto por token si viene con decimales */
      if (amounForToken.toString().search(/./) !== -1) {
        const toArray = amounForToken.toString().split('.');
        amounForToken = new BigNumber(toArray[0]).plus(1);
      }

      const amountToPay = amounForToken.multipliedBy(this.form.value.quantity);
      console.log('amountToPay', amountToPay.toString());

      const amountToPayParsedToTokenDecimals = toTokenDecimals(amountToPay.toString(), tokenDecimals);

      this.spinner.hide();

      // console.log({
      //   unity: unity.toString(),
      //   amounForToken: amounForToken.toString(),
      //   toPrecision: amounForToken.toPrecision(1,0).toString(),
      //   amountToPay: amountToPay.toString(),
      // });
      // return;

      // const amountToAlert = amountToPay.dividedBy(unity).toFixed(4);
      // const ask = await this.sweetAlert2Srv.askConfirm(
      //   `¿Desea comprar ${this.form.value.quantity} ${nftSymbol} por ${amountToAlert} ${tokenSymbol}?`
      // );

      // if(!ask){
      //   return;
      // }

      // console.log({
      //   amountToPay: amountToPay.toPrecision(1, 0).toString(),
      // });

      // return;
      const messageDecimals = (isNative) ? 4 : 2;
      const amountToAlert = amountToPay.dividedBy(unity).toFixed(messageDecimals);
      const message = `¿Desea comprar ${this.form.value.quantity} ${nftSymbol} por ${amountToAlert} ${tokenSymbol}?`;

      switch (isNative) {
        case true:
          return this.buyNative({
            message,
            collectionID,
            tokenAddress: tokenAddress,
            nftSymbol,
            tokenSymbol,
            // amountToPay: amountToPay.toString(),
            amountToPay: amountToPayParsedToTokenDecimals,
          });

        default:
          return this.buyRegular({
            message,
            collectionID,
            // amountToPay: amountToPay.toString(),
            amountToPay: amountToPayParsedToTokenDecimals,
            erc10contractAddress: tokenAddress,
            tokenAddress: nftContract,
            nftSymbol,
            tokenSymbol,
          })
      }

      return;

    } catch (err) {
      console.log('Error on HomeComponent.onSubmit', err);
      return;
    } finally {
      this.spinner.hide();
      this.submit = false;
    }
  }


  /**
   * Compra regular
   * @param params 
   * @returns 
   */
  async buyRegular(params: any) {
    try {

      const {
        message,
        collectionID,
        erc10contractAddress,
        tokenAddress,
        nftSymbol,
        tokenSymbol,
        amountToPay,
      } = params;

      const toFix = calculatePercentage(amountToPay, 10);
      let amountToPayFixed = new BigNumber(amountToPay).plus(toFix);

      if (amountToPayFixed.toString().search(/./) !== -1) {
        const toArray = amountToPayFixed.toString().split('.');
        amountToPayFixed = new BigNumber(toArray[0]).plus(1);
      }

      const result = await this.alertStepSrv.showStepsWithApproved({
        actionMessage: message,
        approvedParams: [erc10contractAddress, environment.contractAddress, amountToPayFixed],
        checkBalanceParams: { contract: erc10contractAddress, amount: amountToPayFixed },
        contractParams: { method: 'vendor_BuyWithCustomToken', params: [erc10contractAddress, collectionID, amountToPayFixed] }
      });

      if (!result.status) {
        return await this.alertStepSrv.showBasicAlert(result.data.message, 'error');
      } else {

        return this.alertStepSrv
          .showAlertWithTxHash({ transactionHash: result.data.transactionHash })
          .then(async (result) => {
            await this.contractSrv.logout(true);
          });
      }

    } catch (err) {
      console.log('Error on HomeComponent.buyRegular', err);
      return;
    }
  }


  /**
   * Compra nativa
   * @param params 
   * @returns 
   */
  async buyNative(params: any) {
    try {

      const {
        message,
        collectionID,
        tokenAddress,
        amountToPay,
      } = params;

      // await this.spinner.show();

      // const amountIncresed = new BigNumber(amountToPay).multipliedBy(new BigNumber(10).pow(18));

      // const checkBalance = await this.contractSrv.checkUserBalanceNative(amountToPay);
      // if(!checkBalance){
      //   this.sweetAlert2Srv.showError('No tiene suficiente saldo');
      //   return;
      // }

      // await this.contractSrv.vendor_BuyWithNativeToken(tokenAddress, 0, amountToPay);

      // this.sweetAlert2Srv.showSuccess('Compra exitosa');
      // return;

      const toFix = calculatePercentage(amountToPay, 10);
      let amountToPayFixed = new BigNumber(amountToPay).plus(toFix);

      if (amountToPayFixed.toString().search(/./) !== -1) {
        const toArray = amountToPayFixed.toString().split('.');
        amountToPayFixed = new BigNumber(toArray[0]).plus(1);
      }




      const result = await this.alertStepSrv.showStepsNative({
        actionMessage: message,
        checkBalanceParams: { amount: amountToPayFixed },
        contractParams: {
          method: 'vendor_BuyWithNativeToken',
          params: [tokenAddress, collectionID, amountToPayFixed],
        },
      });

      // console.log({result});

      if (!result.status) {
        return await this.alertStepSrv.showBasicAlert(
          result.data.message,
          'error'
        );
      } else {
        return this.alertStepSrv
          .showAlertWithTxHash({ transactionHash: result.data.transactionHash })
          .then(() => {
            window.location.reload();
          });
      }

    } catch (err) {
      console.log('Error on HomeComponent.buyNative', err);
      return;
    } finally {
      this.spinner.hide();
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

}
