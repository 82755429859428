import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { fromWei, toWei } from 'src/app/helpers/utils';
import { ContractService } from 'src/app/services/contract.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-withdraw-token-onwer',
  templateUrl: './withdraw-token-onwer.component.html',
  styleUrls: ['./withdraw-token-onwer.component.css']
})
export class WithdrawTokenOnwerComponent implements OnInit {

  public form: FormGroup;
  public submitted = false;

  public pair: any = null;
  public pairList: any[] = [];

  public dataStatus$!: Observable<any>;

  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public contractSrv: ContractService,
    public sweetalert2Service: Sweetalert2Service,
  ) {
    this.form = fb.group({
      amount: [0, [Validators.required]],
      _to: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.dataStatus$ = this.contractSrv.dataStatus$;
  }

  connectWallet() { this.contractSrv.connectAccount(); }


  async loadPairs() {

    let snapshot: any[] = await this.contractSrv.whitelist_tokenList();
    console.log('snapshot', snapshot);

    let result = snapshot.filter((item) => item.active)
      .map((item) =>
        Object.assign({ image: `assets/img/${item?.tokenAddress}.png` }, item)
      ).filter((item) => item.tokenAddress !== "0x000000000000000000000000000000000000dEaD");
    this.pairList = result;

    console.log('pairList', this.pairList);
    // const pairList: any = await this.contractService.whitelist_tokenList();
    // const toFormat: any[] = [];

    //console.log({ pairList });

    // for (const [idx, entry] of Object.entries(pairList)) {
    //   const row: any = entry;
    //   toFormat.push(this.contractService.getTokenName(row, idx));
    // }

    // const result = await Promise.all(toFormat);

    // console.log('pairList', result);
    // console.log({ result });

    // // this.pairList = result.filter((row) => row.active)
    // this.pairList = result.filter((row) => !row.isNative && row.active)

    // console.log({ result: this.pairList });
  }


  async selectPair(item) {
    this.pair = item;
    let balance = await this.contractSrv.calculateAndCallCustomABI({
      contractAddress: item.tokenAddress,
      method: 'balanceOf',
      callType: 'call',
      params: [environment.contractAddress],
      optionals: null,
      urlABI: this.contractSrv.erc20ABI
    });

    let _fromWei = fromWei(balance, 18);
    const _balance = parseFloat(_fromWei).toFixed(4);
    this.pair = Object.assign({}, this.pair, { balance: _balance });
    console.log('pair', this.pair);

    this.form.setValue({
      amount: _balance,
      _to: this.contractSrv.accounts[0],
    });
  }


  removePair() { this.pair = null; }


  // @dev - Set a new Buy limit
  async onSubmit() {

    this.submitted = true;
    const _data = this.form.value;
    console.warn("_data", _data)
    console.log("invalid", this.form.invalid)
    if (this.form.invalid) {
      return;
    }

    try {
      this.spinner.show();
      const _amount = toWei(_data.amount, 18);
      const _to = _data._to;
      const withdraw = await this.contractSrv.withdrawTokenOnwer(this.pair.tokenAddress, _amount, _to);
      return this.sweetalert2Service.showSuccess('Transacción exitosa', 0);
    } catch (err) {
      alert(err);
      console.log('Error on WithdrawTokenOnwerComponent@withdrawTokenOnwer', err);
    } finally {
      this.spinner.hide();
    }
  }
}
