<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container-fluid">
    <a class="navbar-brand"
      ><img src="assets/img/logo.png" class="img-fluid" alt=""
    /></a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <!-- <li class="nav-item">
          <a class="nav-link active" aria-current="page" href="#">Home</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="#">Link</a>
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            Dropdown
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a class="dropdown-item" href="#">Action</a></li>
            <li><a class="dropdown-item" href="#">Another action</a></li>
            <li><hr class="dropdown-divider" /></li>
            <li><a class="dropdown-item" href="#">Something else here</a></li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link disabled"
            href="#"
            tabindex="-1"
            aria-disabled="true"
            >Disabled</a
          >
        </li> -->
      </ul>
      <div class="buttons">
        <ng-container
          *ngIf="dataStatus$ | async as dataStatus; else connectWallet"
        >
          <button class="button-one" (click)="addMetamask.addChainId()">
            Add Network
          </button>
          <button class="button-two" (click)="logout()">
            {{ dataStatus.accounts | truncateWalletAddress }}
          </button>
        </ng-container>

        <ng-template #connectWallet>
          <button class="button-one" (click)="connect()">Add Network</button>
          <button class="button-two" (click)="connect()">Wallet Connect</button>
        </ng-template>
      </div>
    </div>
  </div>
</nav>
