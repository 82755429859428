import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { toWei } from 'src/app/helpers/utils';
import { ContractService } from 'src/app/services/contract.service';
import { Sweetalert2Service } from 'src/app/services/sweetalert2.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-withdraw-native-token',
  templateUrl: './withdraw-native-token.component.html',
  styleUrls: ['./withdraw-native-token.component.css']
})
export class WithdrawNativeTokenComponent implements OnInit {

  public form: FormGroup;
  public submitted = false;
  public nativeToken = environment.chain.nativeCurrency.name;
  public _balance: any


  constructor(
    private spinner: NgxSpinnerService,
    public fb: FormBuilder,
    public contractSrv: ContractService,
    public sweetalert2Service: Sweetalert2Service,
  ) {
    this.form = fb.group({
      _amount: [0, [Validators.required]],
      _to: ["", [Validators.required]],
    });
  }

  async ngOnInit(): Promise<void> {
    this.contractSrv.reInitializating();
    this.contractSrv.accountStatus$.subscribe(async res => {
      if (!res) { return }
      console.log("accountStatus$", res)
      this._balance = await this.contractSrv.getBalanceEth(environment.contractAddress)
      this.form.setValue({
        _amount: this._balance,
        _to: res[0],
      });
    })

  }

  // @dev - Set a new Buy limit
  async onSubmit() {
    this.submitted = true;
    const _data = this.form.value;
    console.warn("_data", _data)

    if (this.form.invalid) {
      return;
    }
    try {
      this.spinner.show();
      const _amount = toWei(_data._amount, 18)
      const withdraw = await this.contractSrv.withdrawMaticOwner(_amount, _data._to);
      return this.sweetalert2Service.showSuccess('Transacción exitosa', 0);
    } catch (err) {
      alert(err)
      console.log('Error on WithdrawMaticOwnerComponent@withdrawMaticOwner', err);
    } finally {
      this.spinner.hide();
    }
    //  this.adminServices.withdrawMaticOwner(_data.value);
  }


}
